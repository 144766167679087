import React, { useEffect } from 'react'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import Label from "../components/form/label"
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

toast.configure({
    position: toast.POSITION.TOP_CENTER
});

export default function ContactForm() {
    const { register, errors, handleSubmit, formState } = useForm();
    const { isSubmitting, isValid } = formState;

    useEffect(() => {
        if (isSubmitting && !isValid) {
            console.log(errors);
            toast.error('Oops! Please check the form for issues');
        }
    })

    const onSubmit = (data, e) => {

        let form_data = new FormData();
        for ( let key in data ) {
            form_data.append(key, data[key]);
        }

        axios.post('https://formspree.io/xqkdvrlj', data).then(res => {
            if (res.status === 200) {
                e.target.reset();
                toast.success('Yipee! Your message has been successfully sent.');
            } else {
                toast('Oops! There\'s a problem submitting the form.');
            }
        });
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-6">
                <Label
                    id="frmName"
                    label="Name"
                />
                <div className="mb-1">
                    <input
                        className="text-gray-900 p-2 rounded-md bg-gray-200 font-bold w-full"
                        id="frmName"
                        name="name"
                        ref={register({ required: true })}
                    />
                </div>
                <div className="text-sm">
                    {errors.name && 'First name is required'}
                </div>
            </div>
            <div className="mb-6">
                <Label
                    id="frmEmail"
                    label="Email"
                />
                <div className="mb-1">
                    <input
                        className="text-gray-900 p-2 rounded-md bg-gray-200 font-bold w-full"
                        id="frmEmail"
                        name="_replyto"
                        ref={register({ required: true })}
                    />
                </div>
                <div className="text-sm">
                    {errors._replyto && 'Email is required'}
                </div>
            </div>
            <div className="mb-6">
                <Label
                    id="frmMessage"
                    label="Message"
                />
                <div className="mb-1">
                    <textarea
                        className="text-gray-900 p-2 rounded-md bg-gray-200 font-bold w-full"
                        id="frmMessage"
                        name="message"
                        rows={5}
                        ref={register({ required: true })}
                    />
                </div>
                <div className="text-sm">
                    {errors.message && 'A message is required'}
                </div>
            </div>
            <div className="">
                <button className="bg-green-700 text-white px-6 py-2 rounded-md">
                    Send Message
                </button>
            </div>
        </form>
    );
}
