import React from "react"
import Layout from "../components/layout"
import Header from "../components/header/"
import ContactForm from "../forms/contact"
import { Helmet } from "react-helmet"

export default () => 
<Layout>
    <Helmet>
        <title>Social Buzz | Facebook &amp; Instagram Marketing for Exmouth Businesses</title>
        <meta name="description" content="Facebook &amp; Instagram marketing for Exmouth businesses" />
    </Helmet>
    <Header />
    <main>
        <div className="max-w-3xl p-6 mx-auto text-center">
            <p className="text-xl mb-6">Coming soon&hellip;</p>
            <p className="mb-6">But for now, you can <a className="text-blue-500 font-bold" href="https://www.facebook.com/socialbuzzexmouth">click to find me on Facebook</a></p>
        </div>
    </main>
    <footer className="bg-gray-900">
        <div className="max-w-xl px-6 py-12 mx-auto text-gray-200">
            <p className="mb-6">Or drop me a message...</p>
            <ContactForm />
        </div>
    </footer>
</Layout>